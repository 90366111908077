export default [
  {
    path: '/about',
    text: 'about',
  },
  {
    path: '/projects',
    text: 'projects',
  },
  {
    path: '/contact',
    text: 'contact',
  },
];
